import React from 'react';
import { Header } from '../components/Header';
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faMapLocation } from '@fortawesome/free-solid-svg-icons';

export const Contact = () => {
    return (
        <>
        <Header/>
        <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
          <div className="uk-container uk-container-center">
            <section
              id="tm-top-a"
              className="tm-top-a uk-grid uk-grid-collapse"
              data-uk-grid-match="{target:'> div > .uk-panel'}"
              data-uk-grid-margin=""
            >
              <div className="uk-width-1-1 uk-row-first">
                <div className="uk-panel">
                  <div
                    className="uk-cover-background uk-position-relative head-wrap"
                    style={{
                      height: 290,
                      backgroundImage: 'url("/public/assets/images/head-bg.jpg")'
                    }}
                  >
                    <img
                      className="uk-invisible"
                      src="/public/assets/images/head-bg.jpg"
                      alt=""
                      height={290}
                      width={1920}
                    />
                    <div className="uk-position-cover uk-flex uk-flex-center head-title">
                      <h1>Contact</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="uk-container uk-container-center alt">
          <ul className="uk-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="uk-active">
              <span>Contact</span>
            </li>
          </ul>
        </div>
        <div className="tm-bottom-a-box  ">
          <div className="uk-container uk-container-center">
            <section
              id="tm-bottom-a"
              className="tm-bottom-a uk-grid"
              data-uk-grid-match="{target:'> div > .uk-panel'}"
              data-uk-grid-margin=""
            >
              <div className="uk-width-1-1 uk-row-first">
                <div className="uk-panel">
                  <div className="contact-page">
                    <div className="uk-grid">
                      <div className="uk-width-1-1">
                        <div className="contact-title">
                          <h2>Lets Chat</h2>
                        </div>
                        <div className="contact-text">
                          If you have an enquiry feel free to call o drops us an email or through our handles on social media platforms. 
                          Feel free to come through our offices during week days
                        </div>
                      </div>
                      <div className="uk-width-1-1">
                        <div className="contact-socials-wrap">
                          <ul className="contact-socials">
                            
                            <li>
                              <a href="http://facebook.com">
                              <FontAwesomeIcon icon={faFacebookF} />
                              </a>
                            </li>
                            <li>
                              <a href="http://x.com">
                              <FontAwesomeIcon icon={faTwitter} />
                              </a>
                            </li>
                          
                           
                            <li>
                              <a href="http://instagram.com">
                              <FontAwesomeIcon icon={faInstagram} />
                              </a>
                            </li>
                            <li>
                              <a href="http://youtube.com">
                              <FontAwesomeIcon icon={faYoutube} />
                              </a>
                            </li>
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="uk-grid"
                      data-uk-grid-match="{target:'.contact-enquiries'}"
                    >
                      <div className="uk-width-medium-1-3 uk-panel">
                        <div style={{ minHeight: 139 }} className="contact-enquiries">
                          <div className="title"> ENQUIRIES</div>
                          <div className="phone">
                          <FontAwesomeIcon icon={'phone'}/>
                            (263)772-216-106
 

                          </div>
                          <div className="mail">
                          <FontAwesomeIcon icon={faMailBulk}/>
                            <a href="malto:juniorsables@zimbabwerugbyunion.co.zw">
                            juniorsables@zimbabwerugbyunion.co.zw
                            </a>
                          </div>
                          <div className="location">

                          <FontAwesomeIcon icon={faMapLocation}/>
                            Prince Edward High School,Centenary Building, Harare
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-medium-1-3 uk-panel">
                        <div style={{ minHeight: 139 }} className="contact-enquiries">
                          <div className="title">MEDIA ENQUIRIES</div>
                          <div className="phone">
                          <FontAwesomeIcon icon={'phone'}/>
                            (263)-242-798-617
                          </div>
                          <div className="mail">
                          <FontAwesomeIcon icon={faMailBulk}/>
                            <a href="malto:media@zimbabwerugbyunion.co.zw">
                            media@zimbabwerugbyunion.co.zw
                            </a>
                          </div>
                          <div className="location">
                          <FontAwesomeIcon icon={faMapLocation}/>
                            Prince Edward High School,Centenary Building, Harare
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-medium-1-3 uk-panel">
                        <div style={{ minHeight: 139 }} className="contact-enquiries">
                          <div className="title">HEAD OFFICE</div>
                          <div className="phone">
                          <FontAwesomeIcon icon={'phone'}/>
                            (263)-242-798-617
                          </div>
                          <div className="mail">
                          <FontAwesomeIcon icon={faMailBulk}/>
                                                      <a href="malto:admin@zimbabwerugbyunion.co.zw">
                            admin@zimbabwerugbyunion.co.zw
                            </a>
                          </div>
                          <div className="location">
                          <FontAwesomeIcon icon={faMapLocation}/>
                            Prince Edward High School,Centenary Building, Harare
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
       
      </>
      
    );
};

 