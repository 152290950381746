import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ResultsResponseModel from '../models/ResultsModelResponse';
import axiosInstance from '../utils/axios';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const url="api/results/";
const filerl= "https://mitambo.co.zw/api/file/"

function useMatchesItems() {
    const fetcher = async () => {
        return (await axiosInstance.get<ResultsResponseModel>(url)).data.list;
    }

    const queryResult = useQuery({
        queryKey:["matches"], 
        queryFn:fetcher}); 
        return { matches: queryResult.data??[], ...queryResult }
     

}
export const Footer = () => {
   const matches = useMatchesItems();
    return (
        <>
              <div className="bottom-wrapper">
    <div className="tm-bottom-f-box  ">
      <div className="uk-container uk-container-center">
        <section
          id="tm-bottom-f"
          className="tm-bottom-f uk-grid"
          data-uk-grid-match="{target:'> div > .uk-panel'}"
          data-uk-grid-margin=""
        >
          <div className="uk-width-1-1">
            <div className="uk-panel">
              <div className="footer-logo">
                <a href="/">
                  <img src="images/footer-logo-img.png" alt="" />
                  Zimbabwe<span> {" "} Junior</span>{" "} Sables
                </a>
              </div>
              <div className="footer-socials">
                <div className="social-top">
                <a href="http://www.facebook.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faFacebookF} />
                            </span>
                          </a>
                          <a href="http://www.x.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faTwitter} />
                            </span>
                          </a>
                          <a href="http://www.instagram.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faInstagram} />
                            </span>
                          </a>
                          <a href="http://www.youtube.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faYoutube} />
                            </span>
                          </a>
          
                </div>
              </div>
              <div className="clear" />
              <p className="footer-about-text">
           
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div className="tm-bottom-g-box  ">
      <div className="uk-container uk-container-center">
        <section
          id="tm-bottom-g"
          className="tm-bottom-f uk-grid"
          data-uk-grid-match="{target:'> div > .uk-panel'}"
          data-uk-grid-margin=""
        >
          <div className="uk-width-1-1 uk-width-large-1-2">
            <div className="uk-panel">
              <div className="match-list-wrap foot">
                <div
                  id="carusel-7"
                  className="uk-slidenav-position"
                  data-uk-slideshow="{ height : 37, autoplay:true, animation:'scroll' }"
                >
                  <div className="last-match-top">
                    <div className="last-match-title">Last match</div>
                    <div className="footer-slider-btn">
                      <a
                        href="/"
                        className="uk-slidenav uk-slidenav-contrast uk-slidenav-previous"
                        data-uk-slideshow-item="previous"
                      />
                      <a
                        href="/"
                        className="uk-slidenav uk-slidenav-contrast uk-slidenav-next"
                        data-uk-slideshow-item="next"
                      />
                    </div>
                    <div className="clear" />
                  </div>
                  <ul className="uk-slideshow">
                  {
                      matches.data?.map((match) => (
                    <li className="" aria-hidden="true" key={match.id}>
                      <div className="match-list-item alt foot">
                        <div className="wrapper">
                          <div className="logo">
                            <a href="/">
                              <img
                             
                                   src={filerl+match.fixtures?.teamAicon}
                                   className="img-polaroid"
                                   alt=""
                                   title=""
                              />
                            </a>
                          </div>
                          <div className="team-name">{match.fixtures?.teamA} </div>
                          <div className="versus">VS</div>
                          <div className="team-name">{match.fixtures?.teamA}  </div>
                          <div className="logo">
                            <a href="match-single.html">
                              <img
                              src={filerl+match.fixtures?.teamBicon}
                              className="img-polaroid"
                              alt=""
                              title=""
                              />
                            </a>
                          </div>
                          <a className="read-more" href="/">
                            View 
                          </a>
                        </div>
                      </div>
                    </li>

                      ))}
            
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-large-1-2">
            <div className="uk-panel">
              <div
                className="acymailing_module"
                id="acymailing_module_formAcymailing54111"
              >
                <div
                  className="acymailing_fulldiv"
                  id="acymailing_fulldiv_formAcymailing54111"
                >
                  <form>
                    <div className="acymailing_module_form">
                      <div className="mail-title">Newsletters</div>
                      <div className="acymailing_introtext">
                   
                      </div>
                      <div className="clear" />
                      <div className="space" />
                      <table className="acymailing_form">
                        <tbody>
                          <tr>
                            <td className="acyfield_email acy_requiredField">
                              <span className="mail-wrap">
                                <input
                                  id="user_email_formAcymailing54111"
                                  className="inputbox"
                                  name="user[email]"
                                  style={{ width: "80%" }}
                                  defaultValue="Enter your email"
                                  title="Enter your email"
                                  type="text"
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="acysubbuttons">
                              <span className="submit-wrap">
                                <span className="submit-wrapper">
                                  <input
                                    className="button subbutton btn btn-primary"
                                    defaultValue="Subscribe"
                                    name="Submit"
                                     type="submit"
                                  />
                                </span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <footer id="tm-footer" className="tm-footer">
      <div className="uk-panel">
        <div className="uk-container uk-container-center">
          <div className="uk-grid">
            <div className="uk-width-1-1">
              <div className="footer-wrap">
                <div className="foot-menu-wrap">
                  <ul className="nav menu">
                    <li className="item-165">
                      <a href="about.html">Home</a>
                    </li>
                    <li className="item-166">
                      <a href="players.html">Team</a>
                    </li>
                    <li className="item-167">
                      <a href="match-list.html">Fixtures</a>
                    </li>
                    <li className="item-168">
                      <a href="results.html">Contact</a>
                    </li>
                    <li className="item-169">
                      <a href="news.html">Sponsors</a>
                    </li>
                  </ul>
                </div>
                <div className="copyrights">
                  Copyright © 2024 <a href="/">Thundrcraft.co.zw</a>. All Rights
                  Reserved.
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
 
        </>
    );
};

 