/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import {
    faFacebookF,
    faInstagram,
    faTwitter,
    faYoutube,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  
  export const Header = () => {
    return (
      <> 
      <div className="toolbar-wrap">
        <div className="uk-container uk-container-center">
          <div className="tm-toolbar uk-clearfix uk-hidden-small">
            <div className="uk-float-right">
              <div className="uk-panel">
                <div className="social-top">
                <a href="https://www.facebook.com/JuniorSablesRugby/">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faFacebookF} />
                            </span>
                          </a>
                          <a href="http://www.x.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faTwitter} />
                            </span>
                          </a>
                          <a href="http://www.instagram.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faInstagram} />
                            </span>
                          </a>
                          <a href="http://www.youtube.com">
                            <span className="uk-icon-small uk-icon-hover ">
                              <FontAwesomeIcon icon={faYoutube} />
                            </span>
                          </a>
          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tm-menu-box">
        <div style={{ height: 70 }} className="uk-sticky-placeholder">
          <nav
            style={{ margin: 0 }}
            className="tm-navbar uk-navbar"
            data-uk-sticky=""
          >
            <div className="uk-container uk-container-right">
              <a className="tm-logo uk-float-left" href="/">
               <span style={{fontSize:"25px"}}>   <em>Zimbabwe</em>
               <img src="../assets/images/logo-sables.png"  style={{"height" : "50px", "width" : "30px"}} alt="logo" title="logo" />{" "}
              
                Junior<em> Sables</em>
                </span>
              </a>
              <ul className="uk-navbar-nav uk-hidden-small">
                <li  className="uk-parent"  data-uk-dropdown="{'preventflip':'y'}"  aria-haspopup="true" aria-expanded="false">
                  <a href="/">Home</a>
                  <div className="uk-dropdown uk-dropdown-navbar uk-dropdown-width-1">
                    <div className="uk-grid uk-dropdown-grid">
                      <div className="uk-width-1-1">
                      
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  data-uk-dropdown="{'preventflip':'y'}"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <a href="team">Team</a>
                </li>
          
                <li
                  className="uk-parent"
                  data-uk-dropdown="{'preventflip':'y'}"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <a href="matches">Fixtures</a>
                
                </li>
              
                <li
                  className="uk-parent"
                  data-uk-dropdown="{'preventflip':'y'}"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <a href="sponsors">Sponsors</a>
                
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
              <a  href="#offcanvas" className="uk-navbar-toggle uk-visible-small"  data-uk-offcanvas=""  />
            </div>
          </nav>
        </div>
      </div>


      </>
    );
  };
  