/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Header } from '../components/Header';
import { useQuery } from '@tanstack/react-query';
import PlayerResponseModel from '../models/PlayerModelResponse';
import axiosInstance from '../utils/axios';
import { BASEURL } from '../utils/constants';
import { Teams } from '../components/Teams';


const url=`${BASEURL}api/player/`;
const fileUrl= `${BASEURL}api/file/`

function useTeamsItems() {
    const fetcherPlayers = async () => {
        return (await axiosInstance.get<PlayerResponseModel>(url)).data.list;
    }
    const queryResult = useQuery({
        queryKey:["teams"], 
        queryFn:fetcherPlayers}); 
        return { teams: queryResult.data??[], ...queryResult }
     

}
export const Team = () => {
    const teams = useTeamsItems()
    return (
        <>
       <Header/>
        <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
          <div className="uk-container uk-container-center">
            <section
              id="tm-top-a"
              className="tm-top-a uk-grid uk-grid-collapse"
              data-uk-grid-match="{target:'> div > .uk-panel'}"
              data-uk-grid-margin=""
            >
              <div className="uk-width-1-1 uk-row-first">
                <div className="uk-panel">
                  <div
                    className="uk-cover-background uk-position-relative head-wrap"
                    style={{
                      height: 290,
                      backgroundImage: 'url("/public/assets/images/rugby-team.jpg"")'
                    }}
                  >
                    <img
                      className="uk-invisible"
                      src="/public/assets/images/rugby-team.jpg"
                      alt=""
                      height={290}
                      width={1920}
                    />
                    <div className="uk-position-cover uk-flex uk-flex-center head-title">
                      <h1>Team</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="uk-container uk-container-center alt">
          <ul className="uk-breadcrumb">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li className="uk-active">
              <span>Team</span>
            </li>
          </ul>
        </div>
        <div className="uk-container uk-container-center">
          <div
            id="tm-middle"
            className="tm-middle uk-grid"
            data-uk-grid-match=""
            data-uk-grid-margin=""
          >
            <div className="tm-main uk-width-medium-1-1 uk-row-first">
              <main id="tm-content" className="tm-content">
                <div className="uk-container uk-container-center tt-gallery-top">
                  <div className="uk-grid" data-uk-grid-match="">
                    <div className="uk-width-medium-3-10 uk-width-small-1-1 title">
                    Shaun DeSouza
                    </div>
                    <div className="uk-width-medium-7-10 uk-width-small-1-1 text">
                    The positives outweigh the negatives.  I would guess you working 
                    hard or haldly working at all


                    </div>
                  </div>
                </div>
                <div className="list-players-wrapper">
                  <div className="uk-sticky-placeholder">
                    <div
                      className="button-group filter-button-group"
                      data-uk-sticky="{top:70, boundary: true}"
                    >
                      <div className="uk-container uk-container-center">
                        <div className="label-menu">OUR Team</div>
                         
                       
                      </div>
                    </div>
                  </div>
                  <div className="list-players-wrap" id="boundary">
                    <div className="left-player">
                      <img src="images/left-player-bg.png" alt="" />
                    </div>
                    <div className="right-player">
                      <img src="/public/assets/images/rugby-team.jpg" alt="" />
                    </div>
                    <div className="uk-container uk-container-center alt">
                      <div className="uk-grid grid1 players-list">
                       <Teams/>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </>
      
    );
};

 