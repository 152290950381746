import React from 'react';
import { Header } from '../components/Header';
 
import { LatestResults } from '../components/LatestResults';
import { TeamPages } from '../components/TeamPages';
import { Footer } from '../components/Footer';
import { SupportPage } from '../components/SupportPage';
import { ContactUs } from '../components/ContactUs';
import Slider from '../components/Slider';
 

const Home = () => {
    return (
        <>
        <Header/>
        <Slider/>
        <LatestResults/>
        <TeamPages/>
        <SupportPage/>
        <ContactUs/>
        <Footer/>
            
        </>
    );
};

export default Home;