import React from 'react';

const Slider = () => {
    return (
        <div>
              <div className="tm-top-a-box tm-full-width  ">
  <div className="uk-container uk-container-center">
    <section
      id="tm-top-a"
      className="tm-top-a uk-grid uk-grid-collapse"
      data-uk-grid-match="{target:'> div > .uk-panel'}"
      data-uk-grid-margin=""
    >
      <div className="uk-width-1-1">
        <div className="uk-panel">
          <div className="akslider-module ">
            <div
              className="uk-slidenav-position"
              data-uk-slideshow="{height: 'auto', animation: 'swipe', duration: '500', autoplay: false, autoplayInterval: '7000', videoautoplay: true, videomute: true, kenburns: false}"
            >
              <ul className="uk-slideshow uk-overlay-active">
                <li
                  aria-hidden="false"
                  className="uk-height-viewport uk-active"
                >
                  <div
                    style={{
                      backgroundImage: "url(assets/images/1.jpg)"
                    }}
                    className="uk-cover-background uk-position-cover"
                  />
                  <img
                    style={{ width: "100%", height: "auto", opacity: 0 }}
                    className="uk-invisible"
                    src="assets/images/1.jpg"
                    alt=""
                  />
                  <div className="uk-position-cover uk-flex-middle">
                    <div className="uk-container uk-container-center uk-position-cover">
                      <div className="va-promo-text uk-width-6-10 uk-push-4-10">
                        <h3>
                          Junior <span>Sables</span>
                        </h3>
                        <div className="promo-sub">
                          We love  <span>the game</span> we are the future
                        </div>
                        <a href="#" className="read-more">
                          Read More
                          <i className="uk-icon-chevron-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" className="uk-height-viewport">
                  <div
                    style={{
                      backgroundImage: "url(assets/images/3.jpg"
                    }}
                    className="uk-cover-background uk-position-cover"
                  />
                  <img
                    style={{ width: "100%", height: "auto", opacity: 0 }}
                    className="uk-invisible"
                    src="assets/images/31.jpg"
                    alt=""
                  />
                  <div className="uk-position-cover uk-flex-middle">
                    <div className="uk-container uk-container-center uk-position-cover">
                      <div className="va-promo-text uk-width-6-10 uk-push-4-10">
                        <h3>
                          We play for the nation.<span>We represent fun.</span>
                        </h3>
                        <div className="promo-sub">
                          We drive <span>our pasion</span> and enjoy the game
                        </div>
                        <a href="#" className="read-more">
                        More Info
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li aria-hidden="true" className="uk-height-viewport">
                  <div
                    style={{
                      backgroundImage: "url(assets/images/4.jpg"}}
                    className="uk-cover-background uk-position-cover"
                  />
                  <img
                    style={{ width: "100%", height: "auto", opacity: 0 }}
                    className="uk-invisible"
                    src="assets/images/4.jpg"
                    alt=""
                  />
                  <div className="uk-position-cover uk-flex-middle">
                    <div className="uk-container uk-container-center uk-position-cover">
                      <div className="va-promo-text uk-width-6-10 uk-push-4-10">
                        <h3>
                          We  <span>are the Future Sables</span>
                        </h3>
                        <div className="promo-sub">
                          No  <span>guts</span> no glory
                        </div>
                        <a href="#" className="read-more">
                           More Info
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <a
                href="/"
                className="uk-slidenav uk-slidenav-contrast uk-slidenav-previous"
                data-uk-slideshow-item="previous"
              />
              <a
                href="/"
                className="uk-slidenav uk-slidenav-contrast uk-slidenav-next"
                data-uk-slideshow-item="next"
              />
              <ul className="uk-dotnav uk-dotnav-contrast uk-position-bottom uk-text-center">
                <li className="uk-active" data-uk-slideshow-item={0}>
                  <a href="/">0</a>
                </li>
                <li data-uk-slideshow-item={1}>
                  <a href="/">1</a>
                </li>
                <li data-uk-slideshow-item={2}>
                  <a href="/">2</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
        </div>
    );
};

export default Slider;