import React from 'react';

export const ContactUs = () => {
    return (
        <>
             <div className="tm-bottom-e-box tm-full-width  ">
    <div className="uk-container uk-container-center">
      <section
        id="tm-bottom-e"
        className="tm-bottom-e uk-grid uk-grid-collapse"
        data-uk-grid-match="{target:'> div > .uk-panel'}"
        data-uk-grid-margin=""
      >
        <div className="uk-width-1-1">
          <div className="uk-panel">
            <div className="map-wrap">
              <div className="contact-form-wrap uk-flex">
                <div className="uk-container uk-container-center uk-flex-item-1">
                  <div className="uk-grid  uk-grid-collapse uk-flex-item-1 uk-height-1-1 uk-nbfc">
                    <div className="uk-width-5-10 contact-left uk-vertical-align contact-left-wrap">
                      <div className="contact-info-lines uk-vertical-align-middle">
                        <div className="item phone">
                          <span className="icon">
                            <i className="uk-icon-phone" />
                          </span>
                          (263)772-216-106
                        </div>
                        <div className="item mail">
                          <span className="icon">
                            <i className="uk-icon-envelope" />
                          </span>
                          <a href="mailto:support@torbara.com">
                          juniorsables@zimbabwerugbyunion.co.zw
                          </a>
                        </div>
                        <div className="item location">
                          <span className="icon">
                            <i className="uk-icon-map-marker" />
                          </span>
                          Prince Edward High School,Centenary Building, Harare
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-medium-5-10 uk-width-small-1-1 contact-right-wrap">
                      <div className="contact-form uk-height-1-1">
                        <div className="uk-position-cover uk-flex uk-flex-column uk-flex-center">
                          <div className="contact-form-title">
                            <h2>Get in touch</h2>
                          </div>
                          <div id="aiContactSafe_form_1">
                            <div
                              className="aiContactSafe"
                              id="aiContactSafe_mainbody_1"
                            >
                              <div className="contentpaneopen">
                                <div id="aiContactSafeForm">
                                  <form>
                                    <div id="displayAiContactSafeForm_1">
                                      <div
                                        className="aiContactSafe"
                                        id="aiContactSafe_contact_form"
                                      >
                                        <div
                                          className="aiContactSafe"
                                          id="aiContactSafe_info"
                                        />
                                        <div
                                          className="aiContactSafe_row"
                                          id="aiContactSafe_row_aics_name"
                                        >
                                          <div className="aiContactSafe_contact_form_field_label_left">
                                            <span
                                              className="aiContactSafe_label"
                                              id="aiContactSafe_label_aics_name"
                                            >
                                              <label htmlFor="aics_name">
                                                Name
                                              </label>
                                            </span>
                                            &nbsp;
                                            <label className="required_field">
                                              *
                                            </label>
                                          </div>
                                          <div className="aiContactSafe_contact_form_field_right">
                                            <input
                                              name="aics_name"
                                              id="aics_name"
                                              className="textbox"
                                              placeholder="Name"
                                              defaultValue=""
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="aiContactSafe_row"
                                          id="aiContactSafe_row_aics_email"
                                        >
                                          <div className="aiContactSafe_contact_form_field_label_left">
                                            <span
                                              className="aiContactSafe_label"
                                              id="aiContactSafe_label_aics_email"
                                            >
                                              <label htmlFor="aics_email">
                                                E-mail
                                              </label>
                                            </span>
                                            &nbsp;
                                            <label className="required_field">
                                              *
                                            </label>
                                          </div>
                                          <div className="aiContactSafe_contact_form_field_right">
                                            <input
                                              name="aics_email"
                                              id="aics_email"
                                              className="email"
                                              placeholder="Email"
                                              defaultValue=""
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="aiContactSafe_row"
                                          id="aiContactSafe_row_aics_message"
                                        >
                                          <div className="aiContactSafe_contact_form_field_label_left">
                                            <span
                                              className="aiContactSafe_label"
                                              id="aiContactSafe_label_aics_message"
                                            >
                                              <label htmlFor="aics_message">
                                                Message
                                              </label>
                                            </span>
                                            &nbsp;
                                            <label className="required_field">
                                              *
                                            </label>
                                          </div>
                                          <div className="aiContactSafe_contact_form_field_right">
                                            <textarea
                                              name="aics_message"
                                              id="aics_message"
                                              cols={40}
                                              rows={10}
                                              className="editbox"
                                              placeholder="Message"
                                              defaultValue={""}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="aiContactSafeBtns">
                                      <div
                                        id="aiContactSafeButtons_center"
                                        style={{
                                          clear: "both",
                                          display: "block",
                                          textAlign: "center"
                                        }}
                                      >
                                        <table
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div id="aiContactSafeSend_loading_1">
                                                  &nbsp;
                                                </div>
                                              </td>
                                              <td id="td_aiContactSafeSendButton">
                                                <input
                                                  id="aiContactSafeSendButton"
                                                  defaultValue="Send"
                                                  type="submit"
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <br />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="map" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div> 
        </>
    );
};

export default ContactUs;