import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const SupportPage = () => {
    return (
        <>
            <div className="tm-top-d-box  ">
    <div className="uk-container uk-container-center">
      <section
        id="tm-top-d"
        className="tm-top-d uk-grid"
        data-uk-grid-match="{target:'> div > .uk-panel'}"
        data-uk-grid-margin=""
      >
        <div className="uk-width-1-1">
          <div className="uk-panel">
            <div className="donate-wrap">
              <div className="donate-inner">
                <h3>
                  <span>Support</span> Team
                </h3>
                <div className="uk-grid">
                  <div className="uk-width-8-10 uk-push-1-10 intro-text">
                  </div>
                </div>
                <form className="teamdonate-form" method="post" target="paypal">
                <span style={{padding:"10px",color:"white"}}>Follow us on our social media pages on our various handles. Join us on facebook, twitter,
                     youtube and on game <br/>days be part of the team .</span>{" "}<br/>

                <a href="http://www.facebook.com">
                            <span className="uk-icon-small  uk-icon-hover" style={{padding:"10px",color:"white"}}>
                              <FontAwesomeIcon icon={faFacebookF} />
                            </span>
                          </a>
                          <a href="http://www.x.com">
                            <span className="uk-icon-small  uk-icon-hover "  style={{padding:"10px",color:"white"}}>
                              <FontAwesomeIcon icon={faTwitter} />
                            </span>
                          </a>
                          <a href="http://www.instagram.com">
                            <span className="uk-icon-small   uk-icon-hover " style={{padding:"10px", color:"white"}}>
                              <FontAwesomeIcon icon={faInstagram} />
                            </span>
                          </a>
                          <a href="http://www.youtube.com">
                            <span className="uk-icon-small   uk-icon-hover " style={{padding:"10px",color:"white"}}>
                              <FontAwesomeIcon icon={faYoutube} />
                            </span>
                          </a>
          
                  <br />
                  <br />
                  <button className="donate-btn" type="submit" name="submit">
                    <span>Follow Us</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>  
        </>
    );
};

 