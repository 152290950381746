import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ResultModel from "../models/ResultModel";
import { BASEURL } from "../utils/constants";

export const LatestResults = () => {
 
     const url =`${BASEURL}api/results/latest`;

     const latestQuery = useQuery({
          queryKey:['latestResults'],
          queryFn:async()=>{
            const  response =await axios.get(url)
            const data = await  response.data.result
            console.log(data as ResultModel)
            return data  as ResultModel;
          }

     })
    return (
        <div className="tm-top-b-box tm-full-width  ">
        <div className="uk-container uk-container-center">
          <section
            id="tm-top-b"
            className="tm-top-b uk-grid"
            data-uk-grid-match="{target:'> div > .uk-panel'}"
            data-uk-grid-margin=""
          >
            <div className="uk-width-1-1">
              <div className="uk-panel">
                <div className="va-latest-wrap">
                  <div className="uk-container uk-container-center">
                    <div className="va-latest-top">
                      <h3>
                        Latest <span>Results</span>
                      </h3>
                      <div className="tournament">
                        <address>
                          {latestQuery.data?.fixtures?.description}
                          <br />
                          <br />
                        </address>{" "}
                      </div>
                      <div className="date">{" "}{latestQuery.data?.fixtures?.time}</div>
                    </div>
                  </div>
                  <div className="va-latest-middle uk-flex uk-flex-middle">
                    <div className="uk-container uk-container-center">
                      <div className="uk-grid uk-flex uk-flex-middle">
                        <div className="uk-width-2-12 center">
                          <a href="results.html">
                            <img
                              src={latestQuery.data?.fixtures?.teamAicon}
                              className="img-polaroid"
                              alt=""
                              title=""
                            />
                          </a>
                        </div>
                        <div className="uk-width-3-12 name uk-vertical-align">
                          <div className="wrap uk-vertical-align-left">
                            {latestQuery.data?.fixtures?.teamA}{" "}
                          </div>
                        </div>
                        <div className="uk-width-2-12 score">
                          <div className="title">score</div>
                          <div className="table">
                            <div className="left"> {latestQuery.data?.teamApoints}</div>
                            <div className="right"> {latestQuery.data?.teamBpoints}</div>
                            <div className="uk-clearfix" />
                          </div>
                        </div>
                        <div className="uk-width-3-12 name alt uk-vertical-align">
                          <div className="wrap uk-vertical-align-middle">{latestQuery.data?.fixtures?.teamB} </div>
                        </div>
                        <div className="uk-width-2-12 center">
                          <a href="results.html">
                            <img
                              src={`/public/flags/4x3/`+latestQuery.data?.fixtures?.teamAicon}
                            
                              className="img-polaroid"
                              alt=""
                              title=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-container uk-container-center">
                    <div className="va-latest-bottom">
                      <div className="uk-grid">
                        <div className="uk-width-8-12 uk-container-center text">
                          <p>
                            
                          </p>
                        </div>
                      </div>
                      <div className="uk-grid">
                        <div className="uk-width-1-1">
                          <div className="btn-wrap uk-container-center">
                            <a className="read-more" href="/">
                              More Info
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      
    );
};

 