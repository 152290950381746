import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { useQuery } from '@tanstack/react-query';
import ResultsResponseModel from '../models/ResultsModelResponse';
import axiosInstance from '../utils/axios';
import { BASEURL } from '../utils/constants';

const url=`${BASEURL}api/results/`;
const filerl= `${BASEURL}api/file/`

function useMatchesItems() {
    const fetcher = async () => {
        return (await axiosInstance.get<ResultsResponseModel>(url)).data.list;
    }

    const queryResult = useQuery({
        queryKey:["matches"], 
        queryFn:fetcher}); 
        return { matches: queryResult.data??[], ...queryResult }
     

}



export const MatchPage = () => {

  const matches = useMatchesItems() 
  const latestResult =  matches.data?.slice(-1).pop()
    return (
        <>
        <Header/>

        <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
    <div className="uk-container uk-container-center">
      <section
        id="tm-top-a"
        className="tm-top-a uk-grid uk-grid-collapse"
        data-uk-grid-match="{target:'> div > .uk-panel'}"
        data-uk-grid-margin=""
      >
        <div className="uk-width-1-1 uk-row-first">
          <div className="uk-panel">
            <div
              className="uk-cover-background uk-position-relative head-match-wrap"
              style={{
                height: 590,
                backgroundImage: 'url("images/head-bg-match.jpg")'
              }}
            >
              <img
                className="uk-invisible"
                src="/images/head-bg-match.jpg"
                alt=""
              />
              <div className="uk-position-cover uk-flex-center head-news-title">
                <h1>Match List</h1>
                <div className="clear" />
                <div className="uk-container uk-container-center">
                  <div className="uk-grid no-marg">
                    <div className="uk-width-6-10 va-single-next-match match-view-wrap">
                      <div className="va-main-next-wrap">
                        <div className="match-list-single">
                          <div className="match-list-item">
                            <div className="count">
                              <div id="countdown4">
                                <div className="counter_container" />
                              </div>
                              <div className="clearfix" />
                            </div>
                            <div className="clear" />
                            <div className="half right">
                              <div className="va-wrap">
                                <div className="logo">
                                  <a href="/">
                                    <img
                                        src={filerl+latestResult?.fixtures?.teamAicon}
                                      className="img-polaroid"
                                      alt=""
                                      title=""
                                    />
                                  </a>
                                </div>
                                <div className="team-name">{latestResult?.fixtures?.teamA}</div>
                              </div>
                            </div>
                            <div className="versus">VS</div>
                            <div className="half left">
                              <div className="va-wrap">
                                <div className="team-name">{latestResult?.fixtures?.teamB}</div>
                                <div className="logo">
                                  <a href="/">
                                    <img
                                      src={filerl+latestResult?.fixtures?.teamBicon}
                                      className="img-polaroid"
                                      alt=""
                                      title=""
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="clear" />
                            <div className="date">
                              <i className="uk-icon-calendar" />
                               {latestResult?.fixtures?.time}
                            </div>
                            <div className="clear" />
                            <div className="location">
                              <i className="uk-icon-map-marker" />
                              <address>
                             {latestResult?.fixtures?.description}<br />
                                <br />
                              </address>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div className="uk-container uk-container-center alt" style={{paddingBottom:"100px"}}>
    <ul className="uk-breadcrumb">
      <li>
        <a href="/">Home</a>
      </li>
      <li className="uk-active">
        <span>Match list</span>
      </li>
    </ul>
    <div className="tm-main uk-width-medium-3-4 uk-push-1-4">
        <main id="tm-content" className="tm-content">
          <div className="match-list-wrap">
            
          {
        matches.data?.map((match) => (

            <div className="match-list-item" key={match.id}>
              <div className="date">
                <span>{}</span>
                {}
              </div>
              <div className="logo">
                <a href="matches">
                  <img
                    src={filerl+match.fixtures?.teamAicon}
                    className="img-polaroid"
                    alt=""
                    title=""
                  />
                </a>
              </div>
              <div className="team-name">{match.fixtures?.teamA?.slice(0,3)}</div>
              <div className="team-score">{match.teamApoints}</div>
              <div className="score-separator">:</div>
              <div className="team-score">{match.teamBpoints}</div>
              <div className="team-name">{match.fixtures?.teamB?.slice(0,3)}</div>
              <div className="logo" style={{alignItems:"center"}}>
                <a href=".">
                  <img
                    src={filerl+match.fixtures?.teamBicon}
                    className="img-polaroid"
                    alt=""
                    title=""
                  />
                </a>
              </div>
              <div className="location">
                <address>
                  {match.fixtures?.description} <br />
                  <br />
                </address>
              </div>
              <div className="va-view-wrap">
                <a className="view-article" href="matches">
                  view
                </a>
              </div>
            </div>
))}
            

          </div>
        </main>
      </div>
  </div>
 
        
        <Footer/>
     
      </>
      

 
    );
};

 