import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
 
import {Team} from './pages/Team';
import { Sponsors } from './pages/Sponsors';
import { Contact } from './pages/Contact';
import { MatchPage } from './pages/MatchPage';
import Home from './pages/Home';
// import { TeamPages } from './components/TeamPages';
import { Teams } from './components/Teams';
 


const queryClient = new QueryClient()
export const App=()=> {
  return (
 
  <QueryClientProvider client={queryClient}>
    <Router>
    <Routes>
      <Route path="/"element={<Home/>}/>
      <Route path="team" element={<Team/>}/>
      <Route path="matches" element={<MatchPage/>}/>
      <Route path="players" element={<Teams/>}/>
      <Route path="sponsors" element={<Sponsors/>}/>
      <Route path="contact" element={<Contact/>}/> 
      <Route/>
    </Routes>

  </Router>
   </QueryClientProvider>

 
  );
}

 
