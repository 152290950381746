import React from 'react';
import { useQuery } from '@tanstack/react-query';
 
import axiosInstance from '../utils/axios';
import PlayerResponseModel from '../models/PlayerModelResponse';
import { BASEURL } from '../utils/constants';

const url=`${BASEURL}api/player/`;
const filerl= `${BASEURL}api/file/`

function useTeamsItems() {
    const fetcherPlayers = async () => {
        return (await axiosInstance.get<PlayerResponseModel>(url)).data.list;
    }
    const queryResult = useQuery({
        queryKey:["teams"], 
        queryFn:fetcherPlayers}); 
        return { teams: queryResult.data??[], ...queryResult }
     

}
export const TeamPages = () => {

const teams = useTeamsItems();
 

    return (
        <>
            
  <div className="tm-bottom-b-box tm-full-width  ">
    <div className="uk-container uk-container-center">
      <section
        id="tm-bottom-b"
        className="tm-bottom-b uk-grid"
        data-uk-grid-match="{target:'> div > .uk-panel'}"
        data-uk-grid-margin=""
      >
        <div className="uk-width-1-1">
          <div className="uk-panel">
            <div className="our-team-main-wrap">
              <div className="uk-container uk-container-center">
                <div className="uk-grid" data-uk-grid-match="">
                  <div className="uk-width-medium-8-10 uk-width-small-1-1 uk-push-1-10">
                    <div className="our-team-wrap">
                      <div className="our-team-title">
                        <h3>
                          Our <span>Team</span>
                        </h3>
                      </div>
                      <div className="our-team-text">
                        <p>
                         Meet our Squad.
                        </p>
                      </div>
                      <div className="our-team-text additional">
                        <p>
                          
                        </p>
                      </div>
                      <div className="team-read-wrap">
                        <a className="team-read-more" href="#">
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                  {
                  teams.data?.slice(5,teams.data.length-4).map((team) => (
                    <div className="uk-width-large-1-4 uk-width-medium-1-3 uk-width-small-1-2 player-item " key={team.id} >
                    <div className="player-article">
                      <div className="wrapper">
                        <div className="img-wrap">
                          <div className="player-number">
                            <span>{team.id}</span>
                          </div>
                          <div className="bio">
                            <span>
                              <a href="/">bio</a>
                            </span>
                          </div>
                          <a href="/">
                            <img
                              src={filerl+team.fileName}
                              className="img-polaroid"
            
                              alt={team.name}
                              title={team.name}
                            />
                          </a>
                          <ul className="socials">
                            <li className="twitter">
                              <a href="http://twitter.com/"  target="_blank"   rel="nofollow" ></a>
                            </li>
                            <li className="facebook">
                              <a href="http://facebook.com/"  target="_blank"  rel="nofollow"  ></a>
                            </li>
                            <li className="linkedin">
                              <a href="https://www.linkedin.com"   target="_blank"  rel="nofollow"  ></a>
                            </li>
                          </ul>
                        </div>
                        <div className="info">
                          <div className="name">
                            <h3>
                              <a href="/">{team.name}{"  "}{team.middleName} {""}</a>
                            </h3>
                          </div>
                          <div className="position">{team.lastName} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}
                 
                  
                  </div>
              </div>
              <div className="our-team-btn">
                <a href="players">
                  <span>More Info</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
        </>
    );
};

 