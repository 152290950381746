import React from 'react';
import { Header } from '../components/Header';
import axiosInstance from '../utils/axios';
import { useQuery } from '@tanstack/react-query';
import SponsorResponseModel from '../models/SponsorResponseModel';
import { BASEURL } from '../utils/constants';



const url=`${BASEURL}api/sponsor/`;
const filerl= `${BASEURL}api/file/`

function useSponsorItems() {
    const fetcher = async () => {
        return (await axiosInstance.get<SponsorResponseModel>(url)).data.list;
    }

    const queryResult = useQuery({
        queryKey:["sponsor"], 
        queryFn:fetcher}); 
        return { sponsors: queryResult.data??[], ...queryResult }
     

}




export const Sponsors = () => {

    const sponsors = useSponsorItems();
    return (
        <>
            <Header/>
  <div className="tm-top-a-box tm-full-width tm-box-bg-1 ">
    <div className="uk-container uk-container-center">
      <section
        id="tm-top-a"
        className="tm-top-a uk-grid uk-grid-collapse"
        data-uk-grid-match="{target:'> div > .uk-panel'}"
        data-uk-grid-margin=""
      >
        <div className="uk-width-1-1 uk-row-first">
          <div className="uk-panel">
            <div
              className="uk-cover-background uk-position-relative head-wrap"
              style={{
                height: 290,
                backgroundImage: 'url("images/head-bg.jpg")'
              }}
            >
              <img
                className="uk-invisible"
                src="images/head-bg.jpg"
                alt=""
                height={290}
                width={1920}
              />
              <div className="uk-position-cover uk-flex uk-flex-center head-title">
                <h1>Corporate Partners</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div className="uk-container uk-container-center alt">
    <ul className="uk-breadcrumb">
      <li>
        <a href="/index.html">Home</a>
      </li>
      <li className="uk-active">
        <span>Sponsors</span>
      </li>
    </ul>
  </div>
  <div className="uk-container uk-container-center">
    <div
      id="tm-middle"
      className="tm-middle uk-grid"
      data-uk-grid-match=""
      data-uk-grid-margin=""
    >
      <div className="tm-main uk-width-medium-1-1 uk-row-first">
        <main id="tm-content" className="tm-content">
          <div id="system-message-container" />
          <div className="jshop" id="comjshop">
            <div className="jshop_list_category"></div>
            <div className="jshop_list_product">

            {
                  sponsors.data?.map((sponsor) => (
                    <div className="jshop list_product" id="comjshop_list_product">
                    <div
                      className="list_product_row uk-grid"
                      data-uk-grid-match="{target:'.uk-panel'}">
                      <div className="uk-width-medium-1-4 uk-panel jswidth25 block_product">
                        <div className="product productitem_14">
                          <div className="image">
                            <div className="image_block">
                              <div className="product_label">
                                <img src={filerl+sponsor.fileName} alt={sponsor.name} />
                              </div>
                              <a href={sponsor.website}>
                                <img
                                  className="jshop_img"
                                  src={filerl+sponsor.fileName}
                                  alt={sponsor.sponsorStatus}
                                  title={sponsor.name}
                                />
                              </a>
                              <div className="name">
                                <h2 className="product_title">
                                  <a href={sponsor.website}>{sponsor.name}</a>
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="mainblock">
                            <div className="price-wrap">
                              <div className="old_price">
                        
                              </div>
                              <div className="jshop_price">
                              
                              </div>
                            </div>
                            <div className="buttons">
                              <a className="button_buy" href={sponsor.website}>
                                View
                              </a>{" "}
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    <div className="nvg_clear" />
                    <div
                      className="list_product_row uk-grid"
                      data-uk-grid-match="{target:'.uk-panel'}"
                    >
          
                    </div>
                    <div className="nvg_clear" />
                    <div
                      className="list_product_row uk-grid"
                      data-uk-grid-match="{target:'.uk-panel'}"
                    >
                   
                    </div>
                    <div className="nvg_clear" />
                  </div>
                  ))
                  
                  }

             
            
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</>

  
    );
};

 