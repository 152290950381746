import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../utils/axios'; // Adjust the import path accordingly

const BASEURL = 'api/player/pages?playerStatus=active&page=';
const PAGE_SIZE = 3;
const DEFAULT_IMAGE_URL="https://mitambo.co.zw/api/file/default.jpg"
interface PlayerResponse {
    totalItems: number;
    tutorials: Array<{
        id: number;
        name: string;
        middleName: string | null;
        lastName: string;
        fileName: string;
    }>;
    totalPages: number;
}

function useTeamsItems(page: number) {
    const fetcherPlayers = async (): Promise<PlayerResponse> => {
        const response = await axiosInstance.get<PlayerResponse>(`${BASEURL}${page}&size=${PAGE_SIZE}`);
        return response.data;
    };

    // Use a descriptive key for the query
 
    return useQuery<PlayerResponse>({
      
           queryKey:["teams", { page, status: 'active' }],
            queryFn: fetcherPlayers});
}

export const Teams = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const { data, isLoading, error } = useTeamsItems(currentPage);

    if (isLoading) {
      return (
          <div className="loading">
              <div className="spinner"></div>
              <p>Loading teams...</p>
          </div>
      );
  }
    if (error) return <div>Error loading teams: {error.message}</div>;

    // Type assertion for data
    const playerData = data as PlayerResponse;

    const handleNextPage = () => {
        if (currentPage < (playerData.totalPages - 1)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <div className="tm-bottom-b-box tm-full-width">
                <div className="uk-container uk-container-center">
                    <section
                        id="tm-bottom-b"
                        className="tm-bottom-b uk-grid"
                        data-uk-grid-match="{target:'> div > .uk-panel'}"
                        data-uk-grid-margin=""
                    >
                        <div className="uk-width-1-1">
                            <div className="uk-panel">
                                <div className="our-team-main-wrap">
                                    <div className="uk-container uk-container-center">
                                        <div className="uk-grid" data-uk-grid-match="">
                                            <div className="uk-width-medium-8-10 uk-width-small-1-1 uk-push-1-10">
                                                <div className="our-team-wrap">
                                                    <div className="our-team-title">
                                                        <h3>
                                                            Our <span>Team</span>
                                                        </h3>
                                                    </div>
                                                    <div className="our-team-text">
                                                        <p>Meet our Squad.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {playerData.tutorials.map((team) => (
                                                <div className="uk-width-large-1-4 uk-width-medium-1-3 uk-width-small-1-2 player-item" key={team.id}>
                                                    <div className="player-article">
                                                        <div className="wrapper">
                                                            <div className="img-wrap">
                                                                <div className="player-number">
                                                                    <span>{team.id}</span>
                                                                </div>
                                                                <a href="/">
                                                                <img
                                                                    src={team.fileName ? `http://mitambo.co.zw/api/file/${team.fileName}` : DEFAULT_IMAGE_URL}
                                                                    className="img-polaroid"
                                                                    alt={team.name || "Default Name"}
                                                                    title={team.name || "Default Name"}
                                                                />
                                                                </a>
                                                            </div>
                                                            <div className="info">
                                                                <div className="name">
                                                                    <h3>
                                                                        <a href="/">{team.name} {team.middleName}</a>
                                                                    </h3>
                                                                </div>
                                                                <div className="position">{team.lastName}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="pagination-controls">
                                        <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                                            Previous
                                        </button>
                                        <span>Page {currentPage + 1} of {playerData.totalPages}</span>
                                        <button onClick={handleNextPage} disabled={currentPage === playerData.totalPages - 1}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};